import { useEffect, useMemo, useState } from 'react';

import { BaseLessonCode } from '@db/collections';
import { DisplayAcademy } from '@models';
import { NewIcon, Spacer } from 'design-system';
import SuperJSON from 'superjson';

import useGetAdvertisementLocation from '@components/Advertisement/get-advertisement-location';
import ToolTip from '@components/ToolTip';
import { sortByDistance } from '@templates/Explore/Explore/screens/ExploreList/actions/use-get-infinite-academies';

import { DEFAULT_LOCATION } from '@constants';
import useGetAcademyListData from '@hooks/use-get-academy-list-data';
import { useGetLicenseByFunnel } from '@hooks/use-get-license-by-funnel';

import AcademyList from './AcademyList';

const HomeToAcademy = () => {
  const userPersonalLicense = useGetLicenseByFunnel();

  const lessonCode: LessonCode | null = userPersonalLicense?.lessonType || 'TWO_AUTO_NEW';

  const latestLocation = useGetAdvertisementLocation();

  const [savedAcademyListItem, setSavedAcademyListItem] = useState<DisplayAcademy[] | null>([]);

  const {
    lat: latitude,
    lng: longitude,
    jibunAddress,
  } = latestLocation || {
    lat: DEFAULT_LOCATION.latitude,
    lng: DEFAULT_LOCATION.longitude,
    jibunAddress: '서울특별시 강남구 역삼동',
  };

  const { academies } = useGetAcademyListData({
    lessonCode,
    academyType: 'academy',
    isOnlyBookableFilter: true,
    latitude,
    longitude,
    isSavedListItems: savedAcademyListItem ? true : false,
  });

  const displayAcademies = useMemo(() => {
    if (!academies) return [];
    if (!latitude || !longitude) return [];
    if (!lessonCode) return [];

    const academiesData = academies
      .map((a: Academy) => {
        // Determine if academy has matching lesson and get displayLessonCode in one pass
        const hasMatchingLesson = a.lessons.some((lesson) => {
          if (lessonCode?.includes('TRAINING')) {
            return lesson.code.includes('TRAINING');
          }
          if (lessonCode?.includes('TWO_SMALL')) {
            return lesson.code.includes('TWO_SMALL');
          }
          return lesson.code === lessonCode;
        });

        if (!hasMatchingLesson) return null;

        const displayLessonCode = lessonCode?.includes('TRAINING')
          ? 'TRAINING'
          : lessonCode?.includes('TWO_SMALL')
          ? 'TWO_SMALL'
          : lessonCode;

        return new DisplayAcademy({ academy: a, lessonCode: displayLessonCode });
      })
      .filter((academy): academy is DisplayAcademy => academy !== null);

    const sortedAcademyList = sortByDistance(academiesData, {
      latitude,
      longitude,
    }).slice(0, 3);

    if (sortedAcademyList && sortedAcademyList.length > 0) {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          'recommendation-list-academy-item-data',
          SuperJSON.stringify(sortedAcademyList),
        );
      }

      return sortedAcademyList;
    }

    return savedAcademyListItem ? savedAcademyListItem : sortedAcademyList;
  }, [academies, latitude, longitude, lessonCode]);

  useEffect(() => {
    const localListAcademyItemData = localStorage.getItem('recommendation-list-academy-item-data');

    if (localListAcademyItemData) {
      const parsedListAcademyItemData = SuperJSON.parse(
        localListAcademyItemData,
      ) as DisplayAcademy[];

      if (parsedListAcademyItemData && parsedListAcademyItemData.length > 0) {
        setSavedAcademyListItem(parsedListAcademyItemData);
      }
    } else {
      setSavedAcademyListItem(null);
    }
  }, []);

  return (
    <>
      {displayAcademies.length > 0 && (
        <div className="flex flex-col px-16 py-32">
          <div className=" flex justify-between">
            <div className="text-new-Section-Title text-new-gray-900">
              가까운{' '}
              {new BaseLessonCode(userPersonalLicense?.lessonType || 'TWO_AUTO_NEW').lessonText}{' '}
              학원 🔍
            </div>
            <ToolTip
              toolTipText={
                displayAcademies.length > 0
                  ? '선택하신 위치 기반의 정보입니다.'
                  : '위치 활성화를 켜서 내 지역을 확인하세요.'
              }
              clickable={true}
            >
              <div className="flex items-center gap-4">
                <div className="text-new-Body2-medium text-new-gray-600">
                  {jibunAddress ? jibunAddress.split(' ')[0].slice(0, 2) : '서울'} 기준
                </div>

                {/* <div className="bg-DTRed-400 flex h-[21px] w-[32px] flex-col items-center justify-center"> */}
                <NewIcon icon="info-outlined" className="fill-new-gray-400" size={20}></NewIcon>
                {/* </div> */}
              </div>
            </ToolTip>
          </div>
          <Spacer className="h-[16px]"></Spacer>
          <div className="bg-new-white flex w-full  rounded-[12px] px-16 py-8">
            <AcademyList academies={displayAcademies}></AcademyList>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeToAcademy;
