import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { NewIcon } from 'design-system';
import { cn } from 'tailwind-config';

interface Props {
  toolTipText: string;
  clickable?: boolean;
  className?: string;
}

const TooltipPolygons = () => {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Polygon 1"
        d="M4.58579 1.41421C5.36683 0.633165 6.63317 0.633165 7.41421 1.41421L12 6L-7.16729e-08 6L4.58579 1.41421Z"
        fill="#242523"
      />
    </svg>
  );
};

export function ToolTip({
  toolTipText,
  children,
  clickable = false,
  className = '',
}: PropsWithChildren<Props>) {
  const [tooltipOpen, setToolTipOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // 모달 외부 클릭 감지
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setToolTipOpen(false); // 외부 클릭 시 툴크 닫기
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    <div className="relative" ref={tooltipRef}>
      <button
        onClick={() => {
          clickable && setToolTipOpen((prev) => !prev);
        }}
      >
        {children}
      </button>
      {tooltipOpen && (
        <div
          className={cn(
            'text-new-white bg-new-gray-900 absolute flex items-start justify-start gap-4 rounded-[8px] px-12 py-8',
            {
              'w-fit whitespace-nowrap':
                !className?.includes('w-') && !className?.includes('whitespace-'),
              'bottom-[-40px]': !className?.includes('bottom-'),
              'right-[-8px]': !className?.includes('right-'),
            },
            className,
          )}
        >
          <span className="text-new-Caption1-bold">{toolTipText}</span>
          <button onClick={() => setToolTipOpen(false)}>
            <NewIcon icon="x-outlined" size={16} className="fill-new-white" />
          </button>
          <div className="absolute right-12 top-[-6px]">
            <TooltipPolygons />
          </div>
        </div>
      )}
    </div>
  );
}

export default ToolTip;
